// src/firebaseInstance.ts
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Database, getDatabase } from 'firebase/database';
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
} from 'firebase/messaging';

// Firebase 설정
const firebaseConfig = {
  apiKey: 'AIzaSyCPGhEYKdq0lDDJxnNbSA2Lw991I0sJzmI',
  authDomain: 'heroranking-cc963.firebaseapp.com',
  databaseURL: 'https://heroranking-cc963-default-rtdb.firebaseio.com',
  projectId: 'heroranking-cc963',
  storageBucket: 'heroranking-cc963.appspot.com',
  messagingSenderId: '831178170460',
  appId: '1:831178170460:web:ec04ee01ccf87299b58699',
  measurementId: 'G-L84ZB27FCY',
};

// Firebase 앱 및 데이터베이스 초기화
const app: FirebaseApp = initializeApp(firebaseConfig);
const database: Database = getDatabase(app);

// Firebase Messaging 객체 선언 (초기값은 null)
let messaging: Messaging | null = null;

// Firebase Messaging 지원 여부 확인 및 초기화
isSupported().then(async supported => {
  if (supported) {
    messaging = getMessaging(app);
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        // FCM 토큰 가져오기
        const token = await getToken(messaging, {
          vapidKey:
            'BBp2JjHk2LNUeK6F61prh0bX-wzu3YG3joWAfW0VgIN_-9M1H1dWV5_dYswhwDJNwBGZeNvyEyMpyF5JjrLNQKE',
        });
        if (token) {
          console.log('FCM Token:', token);
          // 서버에 토큰을 저장하는 API 요청 등을 이곳에서 수행 가능
        } else {
          console.log(
            'No registration token available. Request permission to generate one.',
          );
        }
      }
    } catch (error) {
      console.error('Error getting FCM token or requesting permission:', error);
    }
  } else {
    console.warn('Firebase Messaging은 이 브라우저에서 지원되지 않습니다.');
  }
});

// database와 messaging을 모두 export
export { database, messaging };
