import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import UserManagementTable from './components/UserManagementTable';
import WaitingAccountManagement from './WaitingAccountManagement';
import RegisterAccountGrid from './RegisterAccountGrid';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2, 0, 2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    marginBottom: 16,
  },
  root: {
    flexGrow: 1,
  },
}));

export default function AccountManagement() {
  const classes = useStyles();

  // useEffect(() => {
  //   // 요청 사용자의 푸시 알림 허용
  //   Notification.requestPermission().then(permission => {
  //     if (permission === 'granted') {
  //       // FCM 토큰 가져오기

  //       getToken(messaging, {
  //         vapidKey:
  //           'BBp2JjHk2LNUeK6F61prh0bX-wzu3YG3joWAfW0VgIN_-9M1H1dWV5_dYswhwDJNwBGZeNvyEyMpyF5JjrLNQKE',
  //       })
  //         .then(token => {
  //           if (token) {
  //             console.log('FCM Token:', token);
  //             // 서버에 토큰을 전송하여 저장
  //             // axios
  //             //   .post('/api/save-token', { token })
  //             //   .then(response => {
  //             //     console.log('Token saved to server:', response.data);
  //             //   })
  //             //   .catch(error => {
  //             //     console.error('Error saving token to server:', error);
  //             //   });
  //           } else {
  //             console.log(
  //               'No registration token available. Request permission to generate one.',
  //             );
  //           }
  //         })
  //         .catch(error => {
  //           console.error('Error getting FCM token:', error);
  //         });
  //     }
  //   });
  // }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <RegisterAccountGrid />
      </Paper>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <WaitingAccountManagement />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <UserManagementTable />
        </Paper>
      </Grid>
    </div>
  );
}
