import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
// import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import {
  convertDate,
  errorMessage,
  GLOBAL_HOST_URI,
} from '../../../common/CommonFunc';
const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(0, 2, 0, 2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  root: {
    flexGrow: 1,
  },
  message: {
    color: '#666666', // 회색 계열 색상
    fontSize: '1.2em', // 폰트 크기 조절
    padding: '20px', // 내부 여백 추가
    textAlign: 'center', // 가운데 정렬
  },
}));

export default function WaitingAccountManagement() {
  const [waitingList, setWaitingList] = useState([]);
  const [cookies] = useCookies(['custNo']);
  const classes = useStyles();

  useEffect(() => {
    const init = async () => {
      const { data } = await axios.get(
        GLOBAL_HOST_URI + 'admin/custom/waiting',
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      setWaitingList(data.data);
    };
    init();
  }, []);

  const handle = async (no, yn) => {
    try {
      const config = {
        headers: {
          Authorization: cookies.custNo,
        },
      };

      if (yn == 'N') {
        if (!confirm('정말 거절하시겠습니까?')) {
          return false;
        }
      }

      const { data } = await axios.put(
        `${GLOBAL_HOST_URI}admin/custom/waiting/${no}?useYn=${yn}`,
        null, // 데이터가 없으면 null로 전달합니다.
        config, // 헤더 설정을 포함하는 구성 객체
      );

      if (data.message === 'DUPLICATE_ID') {
        alert('이미 존재하는 아이디입니다.');
        return false;
      }

      if (yn == 'N') {
        alert('거절되었습니다.');
      } else {
        alert('수락하였습니다.');
      }

      location.reload();
    } catch (error) {
      alert('처리 도중 문제가 발생했습니다: ' + error);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Grid item xs={12}>
        <h2>신청 목록</h2>
      </Grid>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>번호</TableCell>
            <TableCell>신청날짜</TableCell>
            <TableCell>아이디</TableCell>
            <TableCell>이메일</TableCell>
            <TableCell>핸드폰 번호</TableCell>
            <TableCell>주사용 분야</TableCell>
            <TableCell>대행사 여부</TableCell>
            <TableCell>설정</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {waitingList.length === 0 ? (
            <TableRow>
              <TableCell colSpan={8}>
                <div className={classes.message}>
                  신청 목록이 존재하지 않습니다.
                </div>
              </TableCell>
            </TableRow>
          ) : null}
          {waitingList.map(item => (
            <TableRow key={item.no}>
              <TableCell>{item.no}</TableCell>
              <TableCell>{convertDate(item.regDate)}</TableCell>
              <TableCell>{item.id}</TableCell>
              <TableCell>{item.kakaoId}</TableCell>
              <TableCell>{item.phone}</TableCell>
              <TableCell>{item.main}</TableCell>
              <TableCell>{item.agency === true ? 'Y' : 'N'}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handle(item.no, 'N')}
                >
                  거절
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handle(item.no, 'Y')}
                >
                  수락
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
