import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainNavigation from './common/MainNaivgation';

import { RecoilRoot } from 'recoil';
import RouteChangeTracker from './utils/RouteChangeTracker';
import NavigatorComponent from './common/NavigatorComponent';

const App: React.FC = () => {
  // useEffect(() => {
  //   const isKakaoBrowser = navigator.userAgent
  //     .toLowerCase()
  //     .includes('kakaotalk');
  //   if (isKakaoBrowser) {
  //     const targetUrl = 'https://heroranking.kro.kr';
  //     window.location.href = `kakaotalk://web/openExternal?url=${encodeURIComponent(
  //       targetUrl,
  //     )}`;
  //   }
  // }, []);

  return (
    <div>
      <RecoilRoot>
        <BrowserRouter basename="/">
          <RouteChangeTracker />
          <MainNavigation NavigationComponent={NavigatorComponent} />
        </BrowserRouter>
      </RecoilRoot>
    </div>
  );
};

export default App;
