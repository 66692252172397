import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  GLOBAL_HOST_URI,
  convertDate,
  errorMessage,
} from '../../../../common/CommonFunc';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import {
  Button,
  Checkbox,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import ChangePasswordPopup from './ChangePasswordPopup';
import SearchIcon from '@material-ui/icons/Search';
import PaymentHistoryPopup from './PaymentHistoryPopup';

const UserManagementTable = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [isChangePasswordPopupOpen, setChangePasswordPopupOpen] =
    useState(false);
  const [isPaymentHistoryPopupOpen, setPaymentHistoryPopupOpen] =
    useState(false);
  const [selectedCustomNo, setSelectedCustomNo] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState('id');

  const handleOpenChangePasswordPopup = no => {
    setSelectedCustomNo(no);
    setChangePasswordPopupOpen(true);
  };

  const handleOpenPaymentHistoryPopup = no => {
    setSelectedCustomNo(no);
    setPaymentHistoryPopupOpen(true);
  };

  const handleCloseChangePasswordPopup = () => {
    setChangePasswordPopupOpen(false);
  };

  useEffect(() => {
    async function init() {
      try {
        const { data } = await axios.get(GLOBAL_HOST_URI + 'custom/list', {
          headers: { Authorization: cookies.custNo },
        });

        const transformedData = data.map(item => ({
          ...item,
          check: false,
        }));

        setUsers(transformedData);
      } catch (e) {
        errorMessage(e);
      }
    }

    init();
  }, []);

  const handleUserFieldChange = useCallback(
    (fieldName, value, user) => {
      setUsers(prevUsers => {
        const userIndex = prevUsers.findIndex(
          u => u.customNo === user.customNo,
        );
        if (userIndex === -1) {
          console.log('fail', prevUsers);
          return prevUsers;
        }
        const updatedUsers = [...prevUsers];
        updatedUsers[userIndex] = {
          ...updatedUsers[userIndex],
          [fieldName]: value,
        };
        return updatedUsers;
      });
    },
    [setUsers],
  );

  const handleCheckboxChange = user => {
    setUsers(prevUsers =>
      prevUsers.map(u =>
        u.customNo === user.customNo ? { ...u, check: !u.check } : u,
      ),
    );
  };
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      // If "전체 선택" is checked, add all users to selectedUsers
      setSelectedUsers(users);
    } else {
      // If "전체 선택" is unchecked, remove all users from selectedUsers
      setSelectedUsers([]);
    }
  };

  const handleModifyClick = async () => {
    try {
      const selectedUsers = users.filter(user => user.check);

      if (selectedUsers.length === 0) {
        alert('수정할 항목을 선택해주세요.');
        return;
      }

      const patchData = selectedUsers.map(user => ({
        customNo: user.customNo,
        customId: user.customId,
        phoneNumber: user.phoneNumber,
        kakaoId: user.kakaoId,
        customName: user.customName,
        memo: user.memo,
      }));

      const { data } = await axios.patch(
        GLOBAL_HOST_URI + 'admin/custom',
        patchData,
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      alert(selectedUsers.length + '개가 수정되었습니다.');

      // 수정 성공 후 상태 초기화
      setSelectAll(false);
      setUsers(prevUsers => prevUsers.map(u => ({ ...u, check: false })));
    } catch (e) {
      console.error('Error during modification:', e);
      // Handle error as needed
    }
  };

  // 추가된 검색 필터 함수
  const filterUsers = user => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const customIdLowerCase = user.customId.toLowerCase();
    const customNameLowerCase = user.customName.toLowerCase();
    const memoLowerCase = (user.memo || '').toLowerCase();

    return (
      (filterBy === 'all' ||
        (filterBy === 'id' &&
          customIdLowerCase.includes(searchTermLowerCase)) ||
        (filterBy === 'name' &&
          customNameLowerCase.includes(searchTermLowerCase))) &&
      (customIdLowerCase.includes(searchTermLowerCase) ||
        customNameLowerCase.includes(searchTermLowerCase) ||
        memoLowerCase.includes(searchTermLowerCase))
    );
  };

  // 추가된 테이블 행 렌더링 부분
  const filteredUsers = useMemo(() => {
    return users.filter(filterUsers);
  }, [users, searchTerm, filterBy]);

  const handleFilterChange = event => {
    setFilterBy(event.target.value);
  };

  const handleDeleteClick = async () => {
    try {
      const selectedUsers = users.filter(user => user.check);

      if (selectedUsers.length === 0) {
        alert('삭제할 항목을 선택해주세요.');
        return;
      }

      const confirmed = window.confirm(
        `${selectedUsers.length}개의 항목을 삭제하시겠습니까?`,
      );

      if (!confirmed) {
        return;
      }

      const customList = selectedUsers.map(user => ({
        customNo: user.customNo,
      }));

      const { data } = await axios.delete(GLOBAL_HOST_URI + 'admin/custom', {
        data: customList,
        headers: { Authorization: cookies.custNo },
      });

      alert(selectedUsers.length + '개가 삭제되었습니다.');

      // Optionally, update the state to remove the deleted users
      setUsers(prevUsers => prevUsers.filter(user => !user.check));
    } catch (e) {
      console.error('Error during deletion:', e);
      // Handle error as needed
    }
  };

  // 수정된 부분: setSearchTerm을 호출할 때 debounce 적용
  const handleSearchTermChange = e => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  const handleTextFieldChange = (fieldName, user) => event => {
    handleUserFieldChange(fieldName, event.target.value, user);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2>유저 관리 테이블</h2>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Select
                value={filterBy}
                onChange={handleFilterChange}
                style={{ minWidth: '150px' }}
                displayEmpty
              >
                <MenuItem value="id">아이디</MenuItem>
                <MenuItem value="name">이름</MenuItem>
              </Select>
            </Grid>
            <Grid item style={{ marginRight: 'auto' }}>
              <OutlinedInput
                placeholder="검색"
                fullWidth
                margin="dense"
                value={searchTerm}
                onChange={handleSearchTermChange}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleModifyClick}
                style={{ marginRight: 8 }}
              >
                수정하기
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDeleteClick}
              >
                삭제하기
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table style={{ minWidth: 1200, overflowX: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </TableCell>
              <TableCell>번호</TableCell>
              <TableCell>신청날짜</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>이메일</TableCell>
              <TableCell>휴대폰 번호</TableCell>
              <TableCell style={{ width: 130 }}>비밀번호 변경</TableCell>
              <TableCell>주사용분야</TableCell>
              <TableCell>프리미엄 만료일</TableCell>
              <TableCell style={{ width: 130 }}>결제내역</TableCell>
              <TableCell>메모</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers.map(user => (
              <TableRow key={user.customNo}>
                <TableCell>
                  <Checkbox
                    checked={user.check}
                    onChange={() => handleCheckboxChange(user)}
                  />
                </TableCell>
                <TableCell>{user.customNo}</TableCell>
                <TableCell>{convertDate(user.regDate)}</TableCell>

                <TableCell>
                  <TextField
                    // multiline
                    size="small"
                    rows={1}
                    fullWidth
                    variant="outlined"
                    value={user.customId}
                    onChange={handleTextFieldChange('customId', user)} // 수정
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    // multiline
                    style={{ padding: 0 }} // 패딩을 0으로 설정
                    rows={1}
                    fullWidth
                    variant="outlined"
                    value={user.kakaoId}
                    onChange={handleTextFieldChange('kakaoId', user)} // 수정
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    // multiline
                    size="small"
                    rows={1}
                    fullWidth
                    variant="outlined"
                    value={user.phoneNumber}
                    onChange={handleTextFieldChange('memo', user)} // 수정
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenChangePasswordPopup(user.customNo)}
                  >
                    변경
                  </Button>
                </TableCell>
                <TableCell>
                  <TextField
                    // multiline
                    size="small"
                    rows={1}
                    fullWidth
                    variant="outlined"
                    value={user.main + (user.agency ? '*' : '')}
                    disabled
                    // onChange={e =>
                    //   handleUserFieldChange('customName', e.target.value, user)
                    // }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    id="datetime-local"
                    type="date" // 날짜 형식으로 설정
                    value={(user.customPremiumExpiredDt || '')
                      .toString()
                      .substring(0, 10)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={async e => {
                      const inputDate = e.target.value;
                      const formattedDate = formatDate(inputDate); // yyyy-mm-dd 형식으로 변환
                      try {
                        const { data } = await axios.put(
                          GLOBAL_HOST_URI + 'custom/expired/' + user.customNo,
                          {
                            customPremiumExpiredDt: formattedDate,
                          },
                          {
                            headers: { Authorization: cookies.custNo },
                          },
                        );
                        // 변경 성공 시 해당 사용자의 customPremiumExpiredDt 값을 업데이트
                        setUsers(prevUsers => {
                          return prevUsers.map(prevUser => {
                            if (prevUser.customNo === user.customNo) {
                              return {
                                ...prevUser,
                                customPremiumExpiredDt: formattedDate,
                              };
                            }
                            return prevUser;
                          });
                        });
                      } catch (e) {
                        errorMessage(e);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenPaymentHistoryPopup(user.customNo)}
                  >
                    결제내역
                  </Button>
                </TableCell>
                <TableCell>
                  <TextField
                    // multiline
                    rows={1}
                    fullWidth
                    variant="outlined"
                    value={user.memo}
                    onChange={handleTextFieldChange('memo', user)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ChangePasswordPopup
        open={isChangePasswordPopupOpen}
        onClose={handleCloseChangePasswordPopup}
        customNo={selectedCustomNo}
      />
      <PaymentHistoryPopup
        open={isPaymentHistoryPopupOpen}
        onClose={() => {
          setPaymentHistoryPopupOpen(false);
        }}
        customNo={selectedCustomNo}
      />
    </div>
  );
};

export default UserManagementTable;
