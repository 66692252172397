import React, { ReactNode, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { rankingDataList } from '../../../store/RankingAtom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { errorMessage, GLOBAL_HOST_URI } from '../../../common/CommonFunc';
import { listCronData } from '../../../utils/listData';
import {
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Paper,
} from '@mui/material';
import DataGridSelectBox from './DataGridSelectBox';
import UpdateTextEdit from './UpdateTextEdit';
import RankingDataType from '../../../types/RankingDataType';
import { atomGroupNo } from '../../../store/CustomGroupAtom';

const CronListComponent: () => JSX.Element | undefined = () => {
  const [rankingData, setRankingData] =
    useRecoilState<RankingDataType[]>(rankingDataList);
  const [groupNo, setGroupNo] = useRecoilState(atomGroupNo);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [loading, setLoading] = useState(false);
  const [updateEditIndex, setUpdateEditIndex] = useState(-1);

  const classes = useStyles();

  const showUpdateKeyword = (index: number) => {
    setUpdateEditIndex(index);
  };

  const deleteKeyword = async (keywordCronNo: number) => {
    try {
      // 삭제 요청
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'cron/delete',
        {
          keywordCronNo: keywordCronNo,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );

      // 삭제 성공 시 메시지 출력
      if (data.message === 'SHOPPING_DELETE_SUCCESS') {
        alert('삭제되었습니다.');

        // 리스트 데이터 갱신
        const listData = await listCronData(0, cookies.custNo);
        setRankingData(listData);
      } else {
        // 삭제 실패 시 메시지 출력
        alert('삭제에 실패하였습니다.');
      }
    } catch (e) {
      // 오류 발생 시 오류 메시지 처리
      errorMessage(e);
    }
  };

  const handleChange = async result => {
    if (!result.destination) return;
    const destinationIndex = result.destination.index;
    const sourceIndex = result.source.index;

    const items = [...rankingData];
    const [reorderedItem] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItem);

    const sourceOrder = rankingData[sourceIndex].keywordCronOrder;
    const keywordCronNo = rankingData[sourceIndex].keywordCronNo;

    const destinationOrder = rankingData[destinationIndex].keywordCronOrder;
    const destinationKeywordCronNo =
      rankingData[destinationIndex].keywordCronNo;

    changeOrder(
      sourceOrder,
      destinationOrder,
      keywordCronNo,
      destinationKeywordCronNo,
      items,
    );
  };

  const changeOrder = async (
    sourceOrder: number, // 드래그시작 요소
    destinationOrder: number, // 드래그 놓는 요소
    keywordCronNo: number,
    destinationKeywordCronNo: number,
    items?: RankingDataType[],
  ) => {
    setLoading(true);
    if (items !== undefined) {
      setRankingData(items);
    }

    try {
      const { data } = await axios.post(
        GLOBAL_HOST_URI + 'cron/changeOrder',
        {
          keywordCronNo: keywordCronNo,
          keywordCronOrder: sourceOrder,
          destinationKeywordCronNo: destinationKeywordCronNo,
          destinationCronOrder: destinationOrder,
        },
        {
          headers: { Authorization: cookies.custNo },
        },
      );
    } catch (e) {
      errorMessage(e);
    }

    const listData = await listCronData(groupNo, cookies.custNo);
    setRankingData(listData);
    setLoading(false);
  };

  let rankingComponent: ReactNode;

  if (Array.isArray(rankingData) && rankingData.length > 0) {
    rankingComponent = rankingData.map((row, index) => (
      <Draggable
        key={index}
        draggableId={index.toString()}
        index={index}
        // style={{ marginBottom: 4, marginTop: 4 }}
      >
        {provided => (
          <TableRow
            key={row.keywordCronNo}
            ref={provided.innerRef}
            // {...provided.dragHandleProps}
            // {...provided.draggableProps}
          >
            <TableCell align="center" style={{ width: '5%' }}>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
              >
                <Button
                  onClick={() => {
                    if (rankingData[index - 1] !== undefined) {
                      changeOrder(
                        row.keywordCronOrder,
                        rankingData[index - 1].keywordCronOrder,
                        row.keywordCronNo,
                        rankingData[index - 1].keywordCronNo,
                      );
                    }
                  }}
                >
                  +
                </Button>
                <Button
                  onClick={() => {
                    if (rankingData[index + 1] !== undefined) {
                      changeOrder(
                        row.keywordCronOrder,
                        rankingData[index + 1].keywordCronOrder,
                        row.keywordCronNo,
                        rankingData[index + 1].keywordCronNo,
                      );
                    }
                  }}
                >
                  -
                </Button>
              </ButtonGroup>
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              align="center"
              style={{ width: '10%' }}
            >
              <DataGridSelectBox
                keywordCronNo={row.keywordCronNo}
                groupNo={row.shoppingGroup?.shoppingGroupNo}
              />
            </TableCell>
            {updateEditIndex === index ? (
              <UpdateTextEdit
                row={row}
                setUpdateEditIndex={setUpdateEditIndex}
                showUpdateKeyword={showUpdateKeyword}
              />
            ) : (
              <>
                <TableCell align="center" style={{ width: '30%' }}>
                  {row.keywordCronProductName}
                </TableCell>
                <TableCell align="center" style={{ width: '20%' }}>
                  {row.keywordCronKeyword}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  {row.keywordCronProductCode}
                </TableCell>
                <TableCell align="center" style={{ width: '10%' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => showUpdateKeyword(index)}
                  >
                    수정하기
                  </Button>
                </TableCell>
              </>
            )}
            <TableCell align="center" style={{ width: '10%' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteKeyword(row.keywordCronNo)}
              >
                삭제하기
              </Button>
            </TableCell>
          </TableRow>
        )}
      </Draggable>
    ));
  }

  return (
    <Grid item xs={12}>
      {loading === true ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">순서</TableCell>
              <TableCell align="center">그룹명</TableCell>
              <TableCell align="center">상품명</TableCell>
              <TableCell align="center">키워드</TableCell>
              <TableCell align="center">상품코드</TableCell>
            </TableRow>
          </TableHead>
          {rankingData.length > 0 ? (
            <DragDropContext onDragEnd={handleChange}>
              <Droppable droppableId="todos">
                {provided => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {rankingComponent}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          ) : null}
        </Table>
      </TableContainer>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default CronListComponent;
