import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import Chip from '@mui/material/Chip';
import {
  GLOBAL_HOST_URI,
  ProgressLoadingComponent,
} from '../common/CommonFunc';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import { useCookies } from 'react-cookie';
import CustomNoRowsOverlay from '../components/CustomNoRowsOverlay';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Calendar from 'react-calendar';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import Stack from '@mui/material/Stack';

import 'react-calendar/dist/Calendar.css';
import { initGroupList } from './rankingCronKeyword/utils/utils';
import { database } from '../firebaseInstance';
import { listRankingData } from '../utils/listData';
import { getDatabase, ref, onValue } from 'firebase/database'; // Firebase 관련 모듈을 임포트

const columns = [
  { field: 'id', headerName: 'id', type: 'number', hide: true },
  { field: 'keywordCronKeyword', headerName: '검색 키워드', width: 250 },
  { field: 'keywordCronProductName', headerName: '상품명', width: 300 },
  { field: 'keywordCronProductCode', headerName: '상품 코드', width: 150 },
  {
    field: 'amRank',
    headerName: '오전 랭킹(PC)',
    width: 170,
    renderCell: (params: GridCellParams) => {
      return params.row.amRank == -1 ? '100페이지 이상' : params.row.amRank;
    },
  },
  {
    field: 'amMobileRank',
    headerName: '오전 랭킹(Mobile)',
    width: 190,
    renderCell: (params: GridCellParams) => {
      return params.row.amMobileRank == -1
        ? '100페이지 이상'
        : params.row.amMobileRank;
    },
  },
  {
    field: 'amDate',
    headerName: '오전 갱신시간',
    width: 170,
  },
  {
    field: 'pmRank',
    headerName: '오후 랭킹(PC)',
    width: 170,
    renderCell: (params: GridCellParams) => {
      return params.row.pmRank == -1 ? '100페이지 이상' : params.row.pmRank;
    },
  },
  {
    field: 'pmMobileRank',
    headerName: '오후 랭킹(Mobile)',
    width: 190,
    renderCell: (params: GridCellParams) => {
      return params.row.pmMobileRank == -1
        ? '100페이지 이상'
        : params.row.pmMobileRank;
    },
  },
  {
    field: 'pmDate',
    headerName: '오후 갱신시간',
    width: 170,
  },
  {
    field: 'detail',
    headerName: '상세보기',
    width: 200,
    renderCell: (params: GridCellParams) => (
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={'/ranking/detailChart/' + params.id}
      >
        상세 보기
      </Button>
    ),
  },
  // { field: 'col6', headerName: '수정/삭제', width: 150 },
];

function CalendarComponent(props) {
  const { setDateFilter, setCalendarFilterVisible, style } = props;
  const [displayDate, setDisplayDate] = useState(new Date());

  useEffect(() => {
    let replaceDate: Date = new Date(displayDate);
    replaceDate.setDate(replaceDate.getDate() + 1);
    const replaceDateStr: string = replaceDate.toISOString().split('T')[0];
    setDateFilter(replaceDateStr);
  }, [displayDate]);

  return (
    <Grid item xs={12} style={style}>
      <Card variant="outlined" style={{ flexWrap: 'inherit' }}>
        <CardContent>
          <Calendar
            onChange={(input: Date) => {
              setDisplayDate(input);
              setCalendarFilterVisible(false);
            }}
            value={displayDate}
            defaultValue={new Date()}
            calendarType={'US'}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function RankingScreen() {
  const [rankingData, setRankingData] = useState([]);
  const [calendarFilterVisible, setCalendarFilterVisible] =
    useState<boolean>(false);
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [loading, setLoading] = useState(false);

  // 필터
  const [dateFilter, setDateFilter] = useState('');
  const [groupNo, setGroupNo] = useState(0);

  const [rankingProgress, setRankingProgress] = useState(false);
  // const rankingRef = firebase.database().ref('/keywordParsing/progress');

  useEffect(() => {
    const init = async () => {
      if (cookies.custNo === undefined) {
        alert('로그인 후 이용해주세요.');

        window.location.replace('/');
        return;
      } else {
        const now = new Date();
        let replaceDate: Date = new Date(now);
        replaceDate.setDate(replaceDate.getDate());
        const replaceDateStr: string = replaceDate.toISOString().split('T')[0];
        setDateFilter(replaceDateStr);

        let data = await listRankingData(cookies, 0);

        setRankingData(data);
      }

      const starCountRef = ref(database, 'keywordParsing/progress'); // keywordParsing/progress

      onValue(starCountRef, snapshot => {
        const data = snapshot.val();
        setRankingProgress(data);
      });
    };
    init();

    // }
  }, []);

  const excelDownload = async () => {
    setLoading(true);
    let xhr = new XMLHttpRequest();
    const customNo = cookies.custNo;

    xhr.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none'; // 'a.style' 대신 'a.style.display'를 사용합니다.
        a.href = URL.createObjectURL(xhr.response);
        a.download = '검색순위엑셀.xlsx';
        a.click();
        document.body.removeChild(a); // 메모리 누수를 방지하기 위해 요소 제거
      }
    };
    xhr.open(
      'GET',
      GLOBAL_HOST_URI + 'ranking/download?customGroupNo=' + groupNo,
    );
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', customNo);
    xhr.send(null);
    setLoading(false);
  };

  useEffect(() => {
    async function getRankingList() {
      if (groupNo !== undefined && dateFilter !== undefined) {
        let data = await listRankingData(cookies, groupNo, dateFilter);
        setRankingData(data);
      }
    }
    getRankingList();
  }, [groupNo, dateFilter]);

  return (
    <div style={{ flexGrow: 1, padding: 8, margin: 8 }}>
      <ProgressLoadingComponent loading={loading} />
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <Stack>
            {rankingProgress == false ? (
              <Chip
                label="랭킹 파싱 대기중"
                variant="outlined"
                color="default"
              />
            ) : (
              <Chip label="랭킹 파싱중" color="success" variant="outlined" />
            )}

            <Button href="#" color="primary" onClick={excelDownload}>
              <SearchIcon />
              엑셀 다운로드
            </Button>
          </Stack>
          {/* <Button
            href="#"
            color="primary"
            onClick={() => {
              init();
              alert('갱신되었습니다.');
            }}>
            <RefreshIcon /> 
            갱신하기
          </Button> */}
        </Grid>
        {cookies?.custAuth !== 'V' ? (
          <Grid item xs={12}>
            <Card variant="outlined" style={{ flexWrap: 'inherit' }}>
              <CardContent>
                그룹명:
                <FilterGroupSelectBox
                  setRankingData={setRankingData}
                  groupNo={groupNo}
                  setGroupNo={setGroupNo}
                  dateFilter={dateFilter}
                />
                <Button
                  variant="contained"
                  color={calendarFilterVisible === true ? 'default' : 'primary'}
                  style={{ marginLeft: 16 }}
                  onClick={() =>
                    setCalendarFilterVisible(!calendarFilterVisible)
                  }
                >
                  최종수집시간 필터
                </Button>
                {dateFilter !== undefined ? (
                  <Chip
                    icon={<InsertInvitationIcon />}
                    label={dateFilter.replace('am', '').replace('pm', '')}
                    // onDelete={handleDelete}
                    style={{ marginLeft: 16 }}
                  />
                ) : null}
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        <CalendarComponent
          setDateFilter={setDateFilter}
          setCalendarFilterVisible={setCalendarFilterVisible}
          style={{
            display: calendarFilterVisible == true ? 'initial' : 'none',
          }}
        />
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                  rows={rankingData}
                  columns={columns}
                  rowHeight={38}
                  // checkboxSelection
                  // disableSelectionOnClick
                  components={{
                    NoRowsOverlay: () => (
                      <CustomNoRowsOverlay title="순위가 존재하지 않습니다." />
                    ),
                  }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

const FilterGroupSelectBox = props => {
  const { groupNo, setGroupNo } = props;
  const [cookies, setCookie, removeCookie] = useCookies(['custNo', 'custAuth']);
  const [groupList, setGroupList] = useState([]);

  const handleChange = async event => {
    setGroupNo(event.target.value);
  };

  useEffect(() => {
    async function init() {
      const getGroupList = await initGroupList(cookies.custNo);
      setGroupList(getGroupList);
    }

    init();
  }, []);

  return (
    <Select
      value={groupNo}
      onChange={handleChange}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value={'0'}>
        <em>전체</em>
      </MenuItem>
      {groupList.map((item, index) => (
        <MenuItem key={index} value={item.shoppingGroupNo}>
          {item.shoppingGroupName}
        </MenuItem>
      ))}
    </Select>
  );
};
