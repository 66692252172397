import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  GLOBAL_HOST_URI,
  convertDate,
  errorMessage,
} from '../../../../common/CommonFunc';
import { Typography, Container, makeStyles, Divider } from '@material-ui/core';
import moment from 'moment'; // moment 라이브러리 추가

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    color: '#777',
  },
  content: {
    whiteSpace: 'pre-wrap',
  },
}));

function NoticeGet() {
  const classes = useStyles();
  const { noticeId } = useParams<{ noticeId: string }>();
  const [notice, setNotice] = useState(null);

  useEffect(() => {
    getNotice();
  }, []);

  async function getNotice() {
    try {
      const { data } = await axios.get(`${GLOBAL_HOST_URI}notices/${noticeId}`);
      setNotice(data.data);
    } catch (error) {
      errorMessage(error);
    }
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      {notice ? (
        <>
          <Typography variant="h5" className={classes.title}>
            {notice.title}
          </Typography>
          <div style={{ textAlign: 'right' }}>
            <Typography variant="subtitle2" className={classes.subtitle}>
              작성 시간: {convertDate(notice.chgDate)} {/* 작성시간 표시 */}
            </Typography>
          </div>
          <Divider />
          <Typography variant="body1" className={classes.content}>
            {notice.contents}
          </Typography>
        </>
      ) : (
        <Typography variant="body1" gutterBottom>
          Loading...
        </Typography>
      )}
    </Container>
  );
}

export default NoticeGet;
